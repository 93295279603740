import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from './navigation/navigation';
import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationTurkish } from './navigation/i18n/tr';

@Component({
    selector   : 'app',
    templateUrl: './app.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls  : ['./app.component.scss?v=${APPCONFIG.cacheVersion}']
})
export class AppComponent implements OnInit, OnDestroy
{
    
    onNameKeyUp(event:any){console.log(event.target.value)}
    navigation: any;
    fuseConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {TranslateService} _translateService
     */
    constructor(
        
        private http:HttpClient,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService
    )
    {
        // Get default navigation
        this.navigation = navigation;
        

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    
    
    getData(){
        return this.http.get('/api/list');
    }
    /**
     * On init
     */
    ngOnInit(): void
    {   
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            if(user && (user.depotAccess || ['QA','WarehouseMan'].includes(user.role.roleType))) {
                var nav = this.navigation[0].children;
                for (let i = 0; i < nav.length; i++) {
                    if(user.role.roleType=='QA' && nav[i].id!=='podQualityCheck') { //Show only POD Quality Check Page to QA
                        nav.splice(i,1);
                        i--; //re-adjust the current index;
                    }
                    else if(user.role.roleType=='WarehouseMan' && nav[i].id!=='jobsummary') { //Show only Job Summary Page to WarehouseMan
                        nav.splice(i,1);
                        i--; //re-adjust the current index;
                    }
                    else if(nav[i].id=='podQualityCheck' 
                            && (user.role.roleType!='Portal Admin' && user.role.roleType!='Guest' && user.role.roleType!='QA')) { // Show POD Quality Check Page only to QA, Portal Admin and Guest
                        nav.splice(i,1);
                        i--; //re-adjust the current index;
                    }
                    else if(user.depotAccess==1 && nav[i].id=='jobsummary') { //Only General Depot Access
                        nav.splice(i,1);
                        i--; //re-adjust the current index;
                    }
                    else if(user.depotAccess==2 
                        && (nav[i].id=='assetmanagement' || nav[i].id=='reports' || nav[i].id=='live-dashboard')) { //Only Bulk Depot Access
                        nav.splice(i,1);
                        i--; //re-adjust the current index;
                    }
                }
            }
        }
        catch(err){
            console.error(err);
        }
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
            this.getData();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
