import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';
import { Component, ElementRef, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { SelectJobDialogComponent } from '@fuse/components/select-job-dialog/select-job-dialog.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { APPCONFIG } from 'app/main/config';
import { HttpService } from 'app/main/services/http.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { COMMON_UTIL } from 'app/main/common_util';

@Component({
    selector: 'app-delivery-documents',
    templateUrl: './delivery-documents.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./delivery-documents.component.scss?v=${APPCONFIG.cacheVersion}']
})
export class DeliveryDocumentsComponent implements OnInit {
    @ViewChild('captureFileInput') captureFileInputVar: ElementRef;
    @ViewChild('uploadFileInput') uploadFileInputVar: ElementRef;
    readonly main_app_logo = APPCONFIG.main_app_logo;
    confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
    selectJobDialog: MatDialogRef<SelectJobDialogComponent>;
    loggedIn=false;
    shipmentId: string;
    postCode: any;
    profileForm: any;
    result:any;
    jobData: any;
    intervalId: any;
    upperListColspan = 1;
    lowerListColspan = 1;
    upperListRowHeight = "5:1";
    lowerListRowHeight = "6:1";
    documents = {uploaded:[], captured:[]};
    allDocumentTypes = [];
    selectedDocumentType='';
    isWarehouseUser = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any, public _matDialogRef: MatDialogRef<any>,
        public dialog: MatDialog, private http1: HttpService, private spinner: NgxSpinnerService, private _router: Router, 
        private ng2ImgMax: Ng2ImgMaxService, private _fuseConfigService: FuseConfigService, private _formBuilder: FormBuilder, 
        private http: HttpClient, private cdr:ChangeDetectorRef, private dateAdapter: DateAdapter<Date>
    ) {
        this._fuseConfigService.config = {  // Configure the layout
            layout: {
                navbar: {hidden: true},
                toolbar: {hidden: true},
                footer: {hidden: true}
            }
        };
        this.dateAdapter.setLocale('en-GB'); // Set date to dd/MM/yyyy
        this._unsubscribeAll = new Subject(); // Set the private defaults
    }

    // @ Lifecycle hooks

    /** On init **/
    ngOnInit(): void {
        this.shipmentId = this.route.snapshot.paramMap.get('shipmentId');
        this.shipmentId = this.shipmentId?this.shipmentId:'';
        this.route.url.subscribe(urlSegments => {
            var url = urlSegments.join('/');
            if(url.includes('warehouse/')) {
                this.isWarehouseUser = true;
                if(!url.includes('warehouse/document/')) {
                    this.shipmentId='';
                }
            }
            console.log('isWarehouseUser:'+this.isWarehouseUser);
        });
        this.onForm();
        this.updateGridLayout(window.innerWidth);
    }
    /** On destroy **/
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    /** ----------------------------------------------------------------------------------------------------- **/

    onResize(event) {
        this.updateGridLayout(event.target.innerWidth);
    }

    updateGridLayout(innerWidth) {
        this.upperListRowHeight="5:1";
        this.lowerListRowHeight="6:1";
        this.upperListColspan = 4;
        this.lowerListColspan = 2;
        if(innerWidth<=410) {
            this.upperListColspan = 1;
            this.lowerListColspan = 1;
            this.lowerListRowHeight="4:1";
        }
        else if(innerWidth>410 && innerWidth<=550) {
            this.upperListColspan = 2;
            this.lowerListColspan = 1;
            this.upperListRowHeight="3:1";
            this.lowerListRowHeight="5:1";
        }
        else if(innerWidth>550 && innerWidth<=800) {
            this.upperListColspan = 2;
            this.lowerListColspan = 1;
            this.upperListRowHeight="4:1";
            this.lowerListRowHeight="6:1";
        }
        else if(innerWidth>800 && innerWidth<=1200) {
            this.upperListRowHeight="3:1";
            this.lowerListRowHeight="5:1";
        }
    }
    
    onForm() {
        this.profileForm = new FormGroup({
            shipmentNo: new FormControl(this.shipmentId, Validators.required),
            postCode: new FormControl(this.postCode, this.isWarehouseUser?null:Validators.required),
            plantCode: new FormControl('', this.isWarehouseUser?Validators.required:null),
            jobPkId: new FormControl('')
        })
    }

    logon() {
        this.spinner.show();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'idToken': APPCONFIG.epwToken
            })
        };
        let body = JSON.stringify(this.profileForm.value);
        this.http.post(APPCONFIG.appUrl+'/deliveryDocument/carrier/jobDetailsForDocumentUpload', body, httpOptions)
        .subscribe(
            data => {
                this.spinner.hide();
                this.result=data;
                var joblist = this.result.data.jobList;
                if(joblist) { // Multiple Jobs found
                    this.selectJobDialog = this.dialog.open(SelectJobDialogComponent,{panelClass: 'select-job-dialog-panel'});
                    this.selectJobDialog.componentInstance.config.title = 'Select';
                    this.selectJobDialog.componentInstance.config.msg='This shipment has multiple deliveries for the given postcode, please select one.'
                    this.selectJobDialog.componentInstance.config.jobs=joblist;
                    
                    this.selectJobDialog.afterClosed().subscribe(result => {
                        if(result.selected) {
                            this.profileForm.controls['jobPkId'].setValue(result.jobPkId);
                            if(this.intervalId){
                                clearTimeout(this.intervalId);
                            }
                            this.spinner.show();
                            this.intervalId = setTimeout(()=>{
                            this.logon()}, 1000)
                        }else {
                            console.log('Job not selected');
                        }
                      });
                }
                else { // Found Job details
                    this.jobData = this.result.data.job;
                    this.allDocumentTypes = this.result.data.documentTypes;
                    this.loggedIn=true;
                }
            },
            err => {
                this.spinner.hide();
                let title = err.error.errorMsg;
                if(!title) {
                    title="Something went wrong. Please try again."
                }
                if (err.error.statusCode == 2 && err.error.httpStatusCode == 400) {
                    this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    this.confirmDialogRefSession.componentInstance.confirmMessage = title;
                }
                else {
                    this.http1.errorHandler(err, title);
                }
            }
        );
    }

    readFile(event: Event, fileSrcType): void {
        var fileType='';
        if (event.type == 'click') {
            return;
        }
        if ((<HTMLInputElement>event.target).files && (<HTMLInputElement>event.target).files[0]) {
            const file = (<HTMLInputElement>event.target).files[0];
            fileType = (file.type=='application/pdf')?'pdf':'jpg';
    
            if (typeof (FileReader) !== 'undefined') {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updateDeliveryDocDetails(reader.result, fileType, fileSrcType);
                };
                reader.readAsDataURL(file);
            }
        }
      }

    updateDeliveryDocDetails(fileSrc, fileType, fileSrcType) {
        fileSrc = COMMON_UTIL.cleanMetadataFromBase64(fileSrc,fileType);
        this.documents[fileSrcType].push({fileSrc: fileSrc, fileType:fileType, fileSrcType:fileSrcType});
    }

    onReset() {
        this.documents={uploaded:[], captured:[]};
        this.selectedDocumentType='';
    }

    backToLogin() {
        this.loggedIn=false;
        this.result=undefined;
        this.jobData=undefined;
        this.onReset();
        this.onForm();
    }

    onSubmit() {
        this.spinner.show();
        let token = APPCONFIG.epwToken;
        const httpOptions = {headers: new HttpHeaders({'Content-Type':'application/json', 'idToken':token})};
        let body = JSON.stringify({jobId:this.jobData.jobPkId, documents:this.prepareDocumentsForUpload(), warehouse:this.isWarehouseUser});
        this.http.post(APPCONFIG.appUrl+'/deliveryDocument/carrier/upload', body, httpOptions)
            .subscribe(data => {
                this.result = data;
                if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201 || this.result.statusCode == 1) {
                    this.spinner.hide();
                    this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                    this.confirmDialogRefSession.componentInstance.confirmMessage = `Documents Uploaded`;
                    this.confirmDialogRefSession.afterClosed().subscribe(result => {
                        this.backToLogin();
                        this.profileForm.reset();
                        this.profileForm.patchValue({shipmentNo: this.shipmentId});
                    });
                }
            },
                err => {
                    this.spinner.hide();
                    let title = err.error.errorMsg;
                    if(!title) {
                        title="Something went wrong. Please try again."
                    }
                    if (err.error.statusCode == 2 && err.error.httpStatusCode == 400) {
                        this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
                        this.confirmDialogRefSession.componentInstance.confirmMessage = title;
                    }
                    else {
                        this.http1.errorHandler(err, title);
                    }
                });

    }

    prepareDocumentsForUpload() {
        let arr=[];
        this.documents.uploaded.forEach(doc => {
            arr.push({documentFileSrc: doc.fileSrc, documentFileType: doc.fileType, documentId: this.selectedDocumentType});
        })
        this.documents.captured.forEach(doc => {
            arr.push({documentFileSrc: doc.fileSrc, documentFileType: doc.fileType, documentId: this.selectedDocumentType});
        })
        return arr;
    }

      
}

